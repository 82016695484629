import React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import PersonToContact from '../components/PersonToContact';
import InterviewFlow from '../components/InterviewFlow';

export default function Careers({ data, location }) {
  const { html, image, frontmatter } = data.career;
  const { title } = frontmatter;
  const jobPostings = data.jobPostings.edges;

  return (
    <Layout>
      <SEO title={title} image={image} url={location.href} />
      <div className="container pt-6">
        <div className="col-12">
          <div className="row">
            <h1>{title}</h1>
          </div>
        </div>
      </div>

      {jobPostings.length > 0 && (
        <div className="strip">
          <div className="container pt-6 pb-6">
            <div className="col-12">
              <div className="row">
                <h3>
                  Du begeisterst dich für Softwareentwicklung, hast Erfahrung
                  mit Microsoft Technologien und suchst nach einer neuen
                  Herausforderung? Sieh dir unsere offenen Positionen an!
                </h3>
              </div>
            </div>
            <div className="row justify-content-start pt-2">
              {jobPostings.map(({ node }) => (
                <div key={node.id} className="col-12 col-md-6">
                  <div className="service service-summary">
                    <div className="service-content">
                      <h2 className="service-title">
                        <Link to={node.fields.slug}>
                          {node.frontmatter.title_short}
                        </Link>
                      </h2>
                      <p>
                        {node.frontmatter.teaser_text}{' '}
                        <Link to={node.fields.slug}>Mehr</Link>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="strip-grey">
        <div className="container pt-6 pb-6">
          <div className="col-12">
            <div className="row">
              <InterviewFlow />
            </div>
          </div>
        </div>
      </div>

      <div className="strip">
        <div className="container pt-6">
          <div className="col-12">
            <div className="row">
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </div>
        </div>
      </div>

      <div className="strip">
        <div className="container pt-6 pb-6">
          <div className="col-12">
            <div className="row">
              <PersonToContact authorId="sascha-kuntze" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    career: markdownRemark(
      fileAbsolutePath: { regex: "/content/careers.md/" }
    ) {
      html
      frontmatter {
        title
        image
      }
      excerpt
    }
    jobPostings: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/careers/.*/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            title_short
            teaser_text
            date
          }
        }
      }
    }
  }
`;
